
import { Component, Vue } from "vue-property-decorator";
import NavButtons from "@/ui-components/NavButtons/NavButtons.vue";
import UserMenuButton from '@/ui-components/UserMenuButton/UserMenuButton.vue';
import namespaces from "@/store/namespaces";
import { AuthorizedUser } from "@/types/users";
import { Getter } from "vuex-class";
import { sharedDropDownActions } from "@/statics/user-drop-down-actions";

@Component({
  name: 'AppBarEnd',
  components: {
    NavButtons,
    UserMenuButton
  },
})
export default class AppBarEnd extends Vue {
  @Getter("userInfo", { namespace: namespaces.AuthModule })
  public userInfo!: AuthorizedUser;

  public dropDownActions = sharedDropDownActions;
}
